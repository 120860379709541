// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels";
import $ from 'jquery';
import Masonry from "masonry-layout";

import 'bootstrap';
import '../stylesheets/application.scss';
require('trix')
require('@rails/actiontext');
require('bootstrap-daterangepicker');

Rails.start()
Turbolinks.start()
ActiveStorage.start()

window.$ = $;

$(document).on('turbolinks:load', function () {
  var elem = document.querySelector('.photo-grid');
  if (elem) {
    var msnry = new Masonry(elem, {
      // options
      itemSelector: '.photo',
      columnWidth: 400,
      gutter: 20,
    });
    $('.photo img', $(elem)).on('load', () => {
      msnry.layout();
    });
  }
  $('.date-picker').daterangepicker({
    singleDatePicker: true,
    showDropdowns: true,
  });
});